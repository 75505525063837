import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"

const Register = () => {
  return (
    <Layout>
      <SEO title="Register" />

      <Navbar />

      <PageBanner pageTitle="Register" />

      <div className="ptb-80">
        <div className="container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSc1wpi6X82ikH5tpbNvei-L5UPnD-mxYmxA3b6toxe41vjjrg/viewform?embedded=true"
            width="1200"
            height="1300"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Register
